.floating_btn{
    position: fixed;
    bottom: 5px;
    right: 5px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    z-index: 1000;

    .contact_icon {
        background-color: rgb(0, 255, 115);
        color: #fff;
        width: 50px;
        height: 50px;
        font-size:30px;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translatey(0px);
        animation: pulse 1.5s infinite;
        box-shadow: 0 0 0 0 rgb(0, 255, 115);
        -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        font-weight: normal;
        font-family: sans-serif;
        text-decoration: none !important;
        transition: all 300ms ease-in-out;

        i{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover{
            background-color: #42db87;
            box-shadow: 0 0 0 30px rgba(66, 219, 135, 0);
        }

      }

      a{
        text-decoration:none;
      }


    @keyframes pulsing {
        to {
          box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
        }
      }

      .text_icon {
        margin-top: 8px;
        color: #000000;
        font-size: 13px;
        font-weight: 600;
      }
}
