.separator_container{
    width: 100%;
    height: 100%;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom left;
        mask-image: linear-gradient(black 70%, transparent);
    }
}