
@import '../../mixins/mixins';
@import '../../base/variables';


.popular_products{

    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 5rem;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 5rem;




    .popular_product_title{
        text-align: start;
        text-align: center;
        margin-top: 0rem;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .popular_product_subtitle{
            text-align: start;
            text-transform: uppercase;
            text-align: center;
            margin-top: 2rem;
        
    }

    .popular_products_container {
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
        gap: 4rem;
        justify-content: center;
        align-items: center;

        @include media-query(tablet) { // $desktop: 992px
            flex-direction: row;
            justify-content: center;
            gap: 6rem;
            flex-wrap: wrap;
        }

        .popular_product{
            max-width: 300px;

            .popular_product_image{
                width: 300px;
                height: 300px;
                border-radius: 10px;
                margin-bottom: 2rem;
                box-shadow: 10px 0 40px 0 rgba(0, 0, 0, 0.1);
                cursor: pointer;

                &:hover{
                    transform: scale(1.1);
                    transition: all 1s ease;
                    box-shadow: 10px 0 40px 0 rgba(0, 0, 0, 0.3);
                }
            }

            .popular_product_title{
                text-transform: capitalize;
                text-align: center;
            }
            
            .popular_product_description{
                text-align: center;
            }

            .button_water_container{
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                bottom: auto;
                transform: none;
                padding-left: 20px;

                button{
                    margin: 0 auto;
                }
            }
        }
    }
}
