@import '../../mixins/mixins';
@import '../../base/variables';


.hero_main_container{
    position: relative;

    .main_title{
        position: absolute;
        width: 80%;
        top: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #003373;
        font-size: 2rem;
        text-transform: uppercase;
        z-index: 1;

        @include media-query(mobilexs) { // $mobilexs: 320px
             
        }            
        @include media-query(mobilex) { // $mobilex: 375px
         
        }            
        @include media-query(mobilexl) { // $mobilexl: 480px
         
        }            
        @include media-query(mobile) { // $mobile: 576px
            font-size: 3rem;
        }            
        @include media-query(tablet) { // $tablet: 768px
        }            
        @include media-query(desktop) { // $desktop: 992px
            width: 50%;
        }            
        @include media-query(desktopL) { // $desktopL: 1200px
        }            
        @include media-query(desktopLX) { // $desktopLX: 1300px
         
        }            
        @include media-query(desktopLS) { // $desktopLS: 1440px
        }            
        @include media-query(desktopXL) { // $desktopXL: 1600px
         
        }            
        @include media-query(desktopXXL) { // $desktopXXL: 1920px
         
        }

        //Remover el before y after
        &::before, &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(254, 254, 254, 0);
            z-index: -1;
        }
    }

    .button_water_container{
        position: absolute;
        top: 7%;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 350px;
        z-index: 1;

        @include media-query(tablet) { // $tablet: 768px
            top: 350px;
        }   
    }

    .hero_container{
        width: 100%;
        height: 700px;
    
        @media only screen and (min-width: 1560px) {
            height: auto;
          }
    
        img {
            width: 100%;
            height: 100%;
            max-height: 100vh;
            object-fit: cover;
            margin-top: -5rem;
        }
    
        
    }
}
