
@import '../../mixins/mixins';
@import '../../base/variables';



.intro_main_container{
    background-color: #eafcfc;
    margin-top: -15rem;
    padding-top: 15rem;
    z-index: -1;
    position: relative;

    

    .intro_container{
        padding: 0 4rem;
        max-width: 1200px;
        padding-top: 4rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4rem;
        padding-bottom: 5rem;


        @include media-query(desktop) { // $desktop: 992px
            flex-direction: row;
            justify-content: center;
            gap: 6rem;
            padding-bottom: 0;
        }  


        

        h2 {
            max-width: 550px;
            text-align: center;
            margin-bottom: 2rem;
            margin-left: auto;
            margin-right: auto;


            @include media-query(desktop) { // $desktop: 992px
                text-align: start;
                margin-left: 0;
            }  
        }

        h3 {
            text-align: center;
            text-align: center;
            margin-top: 2rem;
            text-transform: uppercase;
            max-width: 600px;

            @include media-query(desktop) { // $desktop: 992px
                text-align: start;
            }
        }

        .main_text{
            max-width: 700px;
            text-align: center;

            @include media-query(desktop) { // $desktop: 992px
                text-align: start;
            }
        }

        .cita{
            font-size: 1.6rem;
            font-family: 'Londrina Outline', cursive;
            max-width: 700px;
            text-align: center;
            color: #003373;
            line-height: 1.6;
            letter-spacing: 1px;
            margin-bottom: 0;

            @include media-query(desktop) { // $desktop: 992px
                text-align: start;
            }
        }

        .cita_autor{
            font-size: 1.4rem;
            font-family: 'Londrina Outline', cursive;
            max-width: 700px;
            text-align: center;
            color: #003373;
            line-height: 1.6;
            letter-spacing: 1px;
            margin-bottom: 0;
            font-weight: 800;

            @include media-query(desktop) { // $desktop: 992px
                text-align: start;
            }
        }

        .cita_rol{
            font-size: 1.3rem;
            font-family: 'Londrina Outline', cursive;
            max-width: 700px;
            text-align: center;
            color: #003373;
            line-height: 1.6;
            letter-spacing: 1px;
            margin-bottom: 0;

            @include media-query(desktop) { // $desktop: 992px
                text-align: start;
            }
        }
        


    
        .intro_image_1{
            display: block;
            width: 270px;
            height: auto;
        }
    }
}

