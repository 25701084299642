.repairing_container{
    position: relative;
    mask-image: linear-gradient(transparent, black 7%);

    .repairing_image_container{
        width: 100%;
        height: 700px;
        position: relative;

        .overlay{
            width: 100%;
            height: 100%;
            background-color: rgb(0 11 37 / 77%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        .repairing_image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .repairing_text_box_container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;

        .repairing_text_box{
            background-color: #fff;
            padding: 3rem;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            margin-bottom: 2rem;
            max-width: 600px;


            .repairing_title{
                max-width: 400px;
                text-align: center;
                margin-bottom: 2rem;
                margin-left: auto;
                margin-right: auto;
                font-size: 2.7rem;

                
            }

            .repairing_description{
                margin-bottom: 2rem;
            }

            .button_water_container{
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                bottom: auto;
                transform: none;
                padding-left: 20px;

                button{
                    margin: 0 auto;
                }
            }
        }
    }
}